exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-sanity-article-slug-current-js": () => import("./../../../src/pages/articles/{sanityArticle.slug__current}.js" /* webpackChunkName: "component---src-pages-articles-sanity-article-slug-current-js" */),
  "component---src-pages-become-a-member-js": () => import("./../../../src/pages/become-a-member.js" /* webpackChunkName: "component---src-pages-become-a-member-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-members-index-js": () => import("./../../../src/pages/members/index.js" /* webpackChunkName: "component---src-pages-members-index-js" */),
  "component---src-pages-members-sanity-federation-country-js": () => import("./../../../src/pages/members/{sanityFederation.country}.js" /* webpackChunkName: "component---src-pages-members-sanity-federation-country-js" */),
  "component---src-pages-rulebook-js": () => import("./../../../src/pages/rulebook.js" /* webpackChunkName: "component---src-pages-rulebook-js" */),
  "component---src-pages-tournaments-index-js": () => import("./../../../src/pages/tournaments/index.js" /* webpackChunkName: "component---src-pages-tournaments-index-js" */),
  "component---src-pages-tournaments-sanity-tournament-year-js": () => import("./../../../src/pages/tournaments/{sanityTournament.year}.js" /* webpackChunkName: "component---src-pages-tournaments-sanity-tournament-year-js" */)
}

