import React from "react"
import PropTypes from "prop-types"
import Navbar from "./Navbar"
import Footer from './Footer'
import Seo from "../seo"

// ================
//  MAIN COMPONENT
// ================

const Layout = ({ children, location }) => {
  return (
    <>
      <Seo />
      <Navbar location={location} />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
