import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FaFacebook, FaEnvelope } from "react-icons/fa"
import upbfLogo from "../../images/upbf-logo.png"

export default function Footer() {
  return (
    <StyledFooter>
      <div>
        <a href="https://www.facebook.com/unitedpaintballfederation/">
          <FaFacebook
            title="Facebook"
            aria-label="Facebook Link Button"
            style={{ fontSize: "2rem" }}
          />
        </a>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/members/">Members</Link>
          </li>
          <li>
            <a href="/upbf_rulebook.pdf" target="_blank" rel="noreferrer">
              Rulebook
            </a>
          </li>
          <li>
            <Link to="/media/">Media</Link>
          </li>
          <li>
            <Link to="/about/">About</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
        </ul>
        <a href="mailto:info@upbf.net?Subject=Contact From Website">
          <FaEnvelope />
          &nbsp;&nbsp;info@upbf.net
        </a>
        <img src={upbfLogo} alt="UPBF" />
      </div>
      <p>
        Copyright © {new Date().getFullYear()}, United Paintball Federation, All
        Rights Reserved
      </p>
    </StyledFooter>
  )
}

// ========
//  STYLES
// ========

const StyledFooter = styled.footer`
  background: #555;
  font-size: 0.7rem;

  a {
    text-decoration: none;
  }

  div {
    padding: 0.75rem 1.5rem;
    color: #ccc;
    display: flex;
    justify-content: space-around;
    align-items: center;

    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin: 1rem;
      li {
        display: inline-block;
        margin: 0.5rem 1rem;
      }
    }
  }

  p {
    background: #6a6a6a;
    color: #fff;
    padding: 0.5rem 0;
    text-align: center;
  }

  img {
    height: 35px;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    div {
      flex-direction: column;
      padding: 1.5rem;

      img {
        margin-top: 1rem;
      }
    }
  }
`
