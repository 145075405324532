import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import upbfLogo from "../../images/upbf-logo.png"

export default function Navbar({ location }) {
  const navTogglerRef = useRef({ location: null })

  useEffect(() => {
    if (!navTogglerRef.current.location) {
      navTogglerRef.current.location = location
    } else if (navTogglerRef.current.location !== location) {
      navTogglerRef.current.checked = false
      navTogglerRef.current.location = location
    }
  }, [location])

  return (
    <StyledNavbar>
      <Link to="/" className="home-link">
        <img src={upbfLogo} alt="UPBF" />
      </Link>
      {/* <!-- Hamburger --> */}
      <input type="checkbox" className="toggler" ref={navTogglerRef} />
      <div className="hamburger">
        <div className="ham-line1"></div>
        <div className="ham-line2"></div>
        <div className="ham-line3"></div>
      </div>
      {/* <!-- List --> */}
      <ul>
        <li>
          <Link to="/members/">Members</Link>
        </li>
        <li>
          <a href="/upbf_rulebook.pdf" target="_blank" rel="noreferrer">
            Rulebook
          </a>
        </li>
        <li>
          <Link to="/media/">Media</Link>
        </li>
        <li>
          <Link to="/about/">About</Link>
        </li>
        <li>
          <Link to="/contact/">Contact</Link>
        </li>
      </ul>
    </StyledNavbar>
  )
}

// ========
//  STYLES
// ========
const StyledNavbar = styled.header`
  display: flex;
  justify-content: space-between;
  background: #fff;
  height: 3.5rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;

  .home-link {
    margin: auto 1rem;
    height: 75%;

    img {
      height: 100%;
    }
  }

  .toggler,
  .hamburger {
    position: absolute;
    top: 0;
    right: 0;
    height: 3.5rem;
    width: 3.5rem;
    display: none;
  }

  .toggler {
    z-index: 2;
    opacity: 0;

    &:checked ~ ul {
      transform: translateX(0);
    }

    &:checked ~ .hamburger .ham-line1 {
      transform: rotate(-45deg);
    }
    &:checked ~ .hamburger .ham-line2 {
      opacity: 0;
    }
    &:checked ~ .hamburger .ham-line3 {
      transform: rotate(45deg);
    }
  }

  .hamburger {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 7.5px;

    .ham-line1,
    .ham-line2,
    .ham-line3 {
      background: var(--primary-color);
      display: block;
      width: 75%;
      height: 3px;
      transform-origin: right;
      transition: all 0.6s;
    }
  }

  ul {
    display: flex;
    height: 100%;
    margin-right: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    list-style: none;

    li {
      a {
        color: var(--primary-color);
        display: block;
        padding: 0 1.5rem;
        line-height: 3.5rem;
        position: relative;
        transition: color 0.3s;

        &:before {
          content: "";
          background: var(--primary-color);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          transform-origin: left;
          transform: skew(-20deg) scaleX(0);
          transition: transform 0.4s;
          z-index: -1;
        }

        &:hover {
          color: #fff;

          &:before {
            transform: skew(-20deg) scaleX(1);
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 699.98px) {
    .toggler {
      display: block;
    }
    .hamburger {
      display: flex;
    }

    ul {
      background: var(--primary-color);
      position: fixed;
      top: 3.5rem;
      bottom: 0;
      right: 0;
      height: calc(100vh - 3.5rem);
      width: 80vw;
      margin-right: 0;
      padding-top: 2rem;
      text-align: center;
      flex-direction: column;
      align-items: center;
      transform: translateX(100%);
      transition: transform 0.6s;

      li {
        width: 100%;

        a {
          color: #fff;
          margin: 0;
          padding: 1.4rem;
          font-size: 1.4rem;
          line-height: normal;

          &:before {
            display: none;
          }
        }
      }
    }
  }
`
